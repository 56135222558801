.logo img {
  width: 90px;
}
#main {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/hero-back.svg");
  font-family: "Inter", sans-serif;
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}
nav.active {
  box-shadow: 2px 10px 15px rgba(0, 247, 255, 0.1);
  background-color: #ffffff;
}
nav .menu-list {
  display: flex;
}
.active {
  background-color: #00b7ff;
  color: #ffffff;
  transition: all ease 0.2s;
}
nav .menu-list li a {
  height: 40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
nav .menu-list li a:hover {
  background-color: var(--blue-hover);
  border-radius: 30px;
  color: #ffffff;
  transition: all ease 0.2s;
}

.menu {
  display: contents;
}

nav .menu-btn,
.menu-icon {
  display: none;
}
.name {
  width: 500px;
  position: absolute;
  left: 22%;
  top: 50%;
  transform: translate(-22%, -50%);
}
.name span {
  color: #00b7ff;
}
.name .details {
  margin-top: 1rem;
  color: #3b3b3be0;
}
.name h1 {
  font-size: 40px;
  margin: 0px;
  letter-spacing: 2px;
  color: #000000;
}

.hero-right {
  width: 500px;
  position: absolute;
  right: 22%;
  top: 50%;
  transform: translate(35%, -50%);
}

.header-btns {
  display: flex;
  margin-top: 1rem;
}
.cv-btn {
  width: 110px;
  height: 40px;
  cursor: pointer;
  margin-right: 1rem;
  background-color: var(--blue);
  border-radius: 30px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 1rem;
}

.header-btns .cv-btn {
  width: 140px;
}
.cv-btn:hover {
  background-color: var(--blue-hover);
  transition: all ease 0.5s;
  color: #ffffff;
}

.cv-btn-white {
  width: 110px;
  height: 40px;
  cursor: pointer;
  margin-right: 1rem;
  background-color: var(--white);
  border: 1px solid var(--blue);
  border-radius: 30px;
  color: var(--black);
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 1rem;
}
.cv-btn-white:hover {
  background-color: var(--white);
  transition: all ease 0.5s;
  color: var(--black);
}

.cv-btn-white a {
  color: var(--black);
}

#contact {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--blue);
}
#contact h3 {
  padding: 1rem 0;
  color: #fff;
  font-size: 2rem;
}
#contact form {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: var(--white);
}
#contact form input,
#contact form textarea {
  width: 100%;
  height: 50px;
  margin: 5px 0px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #c3c3c3;
  color: var(--black);
  border-radius: 5px;
}
#contact form textarea {
  height: 150px;
}
#contact form input[type="submit"] {
  height: 45px;
  background: var(--blue);
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

@media screen and (max-width: 2561px) and (min-width: 1450px) {
  .hero-right {
    width: 500px;
    position: absolute;
    right: 28%;
    top: 70%;
    transform: translate(35%, -50%);
  }

  .hero-right img {
    width: 100%;
  }

  .name {
    width: 500px;
    position: absolute;
    left: 22%;
    top: 65%;
    transform: translate(-22%, -50%);
  }
}

@media screen and (max-width: 1445px) and (min-width: 1190px) {
  .hero-right {
    width: 500px;
    position: absolute;
    right: 22%;
    top: 50%;
    transform: translate(35%, -50%);
  }

  .name {
    width: 500px;
    position: absolute;
    left: 22%;
    top: 50%;
    transform: translate(-22%, -50%);
  }
}

@media (max-width: 1190px) {
  #main {
    background-size: 1150px !important;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .hero-right {
    display: none;
  }

  nav .menu-list li a:hover {
    transition: all ease 0.2s;
  }
}

@media (max-width: 970px) {
  .main {
    background-image: none !important;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .header-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .cv-btn {
    margin: 1rem auto;
  }

  .cv-btn-white {
    margin: 0.5rem auto;
  }
}
@media (max-width: 600px) {
  .name {
    width: 60%;
  }

  #contact h3 {
    padding: 1.5rem 0;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
  }

  #contact form {
    width: 70%;
  }
}
.scroll {
  left: 42.5%;
}
@media (max-width: 600px) {
  nav {
    position: absolute;
  }
}
@media (max-width: 1100px) {
  .menu-icon {
    display: block;
  }
  nav {
    justify-content: space-between;
    height: 65px;
    padding: 0px 30px;
  }

  nav ul {
    display: flex;
    flex-direction: column;
  }
  .logo img {
    width: 70px;
  }
  .header-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-weight: 700;
  }

  .menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0px;
    background-color: #ffffff;
    border-bottom: 4px solid #1db096;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
  .menu li {
    width: 100%;
  }
  nav .menu li a {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 25px;
    border: 1px solid rgba(38, 38, 38, 0.03);
  }
  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  nav .menu-icon .nav-icon {
    background-color: #333333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }
  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }
  nav .menu-icon .nav-icon:before {
    top: 5px;
  }
  nav .menu-icon .nav-icon:after {
    top: -5px;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
  }
  nav .menu-btn {
    display: none;
  }
  nav .menu-btn:checked ~ .menu {
    display: block;
  }
}
